import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  forwardRef,
} from "react";
import type { PropsWithChildren } from "react";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import { CLIENT_SESSION_STORAGE_KEY, SELECTED_SERVICE } from "constants/keys";
import { ROUTES } from "constants/routes";
import { CacheService } from "services/cache";

import * as Types from "./types";
import { ApiService } from "services/api";
import dayjs from "dayjs";
import { INPUT_MASK } from "constants/masks";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  reference
) {
  return <MuiAlert elevation={6} ref={reference} variant="filled" {...props} />;
});

const GlobalContext = createContext<Types.GlobalState>({} as Types.GlobalState);

export const GlobalStateProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [client, setClient] = useState<Clients>();
  const [services, setServices] = useState<Services[]>([]);
  const [selectedService, setSelectedService] = useState<Services>()
  const logged = !!client;

  const [snackbarSettings, setSnackbarSettings] = useState<
    SnackbarProps & Pick<AlertProps, "severity">
  >({
    open: false,
  } as SnackbarProps);

  const handleSelectService = useCallback((selectedService?: Services) => {
    setSelectedService(selectedService);
    localStorage.setItem(SELECTED_SERVICE, JSON.stringify(selectedService))
  }, []);

  const closeToastMessage = useCallback(() => {
    setSnackbarSettings({ open: false });
  }, []);

  const openToast = useCallback(
    (props: Types.OpenToastMessage & Pick<AlertProps, "severity">) => {
      setSnackbarSettings({
        open: true,
        autoHideDuration: 6_000,
        onClose: closeToastMessage,
        severity: "success",
        ...props,
      });
    },
    [closeToastMessage]
  );

  const openSuccessToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: "success", ...props });
    },
    [openToast]
  );

  const openErrorToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: "error", ...props });
    },
    [openToast]
  );

  const openWarningToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: "warning", ...props });
    },
    [openToast]
  );

  const openInfoToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: "info", ...props });
    },
    [openToast]
  );

  const saveUser = useCallback((currentClient: LoginApiResponse["client"]) => {
    setClient({
      ...currentClient,
    });
  }, []);

  const logout = useCallback(() => {
    CacheService.client.clear();
    window.location.href = ROUTES.ROOT;
  }, []);

  const getServices = useCallback(async () => {
    const response = await ApiService.Services.getAll();
    setServices(response);
  }, []);
  useEffect(() => {
    getServices();
  }, [getServices]);

  useEffect(() => {
    const cachedUser = localStorage.getItem(CLIENT_SESSION_STORAGE_KEY);

    if (cachedUser) {
      const currentUser = JSON.parse(cachedUser);
      setClient({ ...currentUser });
      ApiService.persistToken(currentUser.token);
      return;
    }
  }, []);


  return (
    <GlobalContext.Provider
      value={{
        client,
        saveUser,
        logged,
        logout,
        openSuccessToast,
        openErrorToast,
        openWarningToast,
        openInfoToast,
        services,
        selectedService,
        handleSelectService,
      }}
    >
      {children}

      <Snackbar {...snackbarSettings}>
        <Alert
          onClose={closeToastMessage}
          severity={snackbarSettings.severity}
          sx={{ width: "100%" }}
        >
          {snackbarSettings.message}
        </Alert>
      </Snackbar>
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error(
      "useGlobalState should be encapsuled inside GlobalContextProvider"
    );
  }

  return useContext(GlobalContext);
};
