import { QueryClient } from "@tanstack/react-query";
import { CLIENT_SESSION_STORAGE_KEY, JWT_EXPIRATION_DATE, PUBLIC_JWT_EXPIRATION_DATE, PUBLIC_TOKEN_SESSION_STORAGE_KEY, SELECTED_SERVICE } from "constants/keys";

const client = {
  saveCurrentUser: (currentUser: LoginApiResponse) => {
    window.localStorage.setItem(
      CLIENT_SESSION_STORAGE_KEY,
      JSON.stringify(currentUser)
    );
  },
  saveCurrentPublicToken: (currentPublicToken: string) => {
    window.localStorage.setItem(PUBLIC_TOKEN_SESSION_STORAGE_KEY, currentPublicToken)
  },
  saveExpirationTokenDate: (timestamp: number) => {
    window.localStorage.setItem(JWT_EXPIRATION_DATE, (timestamp * 1_000).toString())
  },
  saveExpirationPublicTokenDate: (timestamp: number) => {
    window.localStorage.setItem(PUBLIC_JWT_EXPIRATION_DATE, (timestamp * 1_000).toString())
  },
  getExpirationTokenDate: (): Promise<Date> => {
    return new Promise((resolve, reject) => {
      try {
        const expirationDate = window.localStorage.getItem(JWT_EXPIRATION_DATE)

        if (expirationDate) {
          resolve(new Date(Number(expirationDate)))
        }

        reject()
      } catch (getExpirationTokenDateError) {
        reject()
      }
    })
  },
  getExpirationPublicTokenDate: (): Promise<Date> => {
    return new Promise((resolve, reject) => {
      try {
        const expirationDate = window.localStorage.getItem(PUBLIC_JWT_EXPIRATION_DATE)

        if (expirationDate) {
          resolve(new Date(Number(expirationDate)))
        }

        reject()
      } catch (getExpirationTokenDateError) {
        reject()
      }
    })
  },
  getCurrentPublicToken: () => {
    return window.localStorage.getItem(PUBLIC_TOKEN_SESSION_STORAGE_KEY)
  },
  getCurrentClient: () => {
    return window.localStorage.getItem(CLIENT_SESSION_STORAGE_KEY)
  },
  clear: () => {
    window.localStorage.removeItem(CLIENT_SESSION_STORAGE_KEY);
    window.localStorage.removeItem(SELECTED_SERVICE);
  },
};

const queryClient = new QueryClient();

export const CacheService = {
  client,
  queryClient,
};
