import React from 'react'
import styled from 'styled-components'

export const Paragraph = styled.p`
  display: flex;
  text-align: center;
  font-family: var(--rubik-font);
`

export default function Text({ children }: React.PropsWithChildren<unknown>) {
  return <Paragraph>{children}</Paragraph>
}
