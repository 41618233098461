import { ROUTES } from "constants/routes";
import * as Pages from "pages";
import type { RouteObject } from "react-router-dom";

const RootPageRoutes: RouteObject[] = [
  {
    path: ROUTES.ROOT,
    element: <Pages.FirstPage />,
  },
  {
    path: ROUTES.SERVICES.VIEW(':id'),
    element: <Pages.ServiceDetails />,
  },
];

export default RootPageRoutes;
