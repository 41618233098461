import Loading from "components/Loading";
import * as Styled from './styles'

export default function LoadingFullPageWithOverlay() {
  return (
    <Styled.Overlay data-testid="modal_overlay">
        <Loading sx={{ color: '#FFF'}} />
    </Styled.Overlay>
  );
}
