import axios, { AxiosError } from "axios";
import { ROUTES } from "constants/routes";

import { CacheService } from "../cache";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
  if (
    error.status === 401 &&
    (error.response?.data as ApiError).message === "JWT token missing."
  ) {
    // FIXME: create an route to refresh token
    CacheService.client.clear();
    window.location.href = ROUTES.LOGIN;
  }

  return Promise.reject(error);
});

export default axiosInstance;
