import { AxiosError } from "axios";
import { ENDPOINTS } from "constants/endpoints";
import apiInstance from "services/api/instance";
import { handleErrorMessage } from "../errors";

export const get = async (): Promise<Public> => {
  try {
    const getAllResponse = await apiInstance.get<Public>(
      ENDPOINTS.PUBLIC_KEY.GET,
      {
        headers: {
          "Content-Type": "application/json",
          "public-key": process.env.REACT_APP_PUBLIC_KEY,
        },
      }
    );

    return getAllResponse.data;
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};
