import { useGlobalState } from "contexts/global-state";
import { useForm } from "react-hook-form";
import Header from "components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import LoadingFullPage from "components/LoadingFullPage";
import { ApiService } from "services/api";
import { Box, Button, Card, CardContent, Container, Grid } from "@mui/material";
import InputPassword from "components/InputPassword";
import { yupResolver } from "@hookform/resolvers/yup";
import { newPassword } from "./form-validation";
import { useCallback, useState } from "react";

type ChangePasswordProps = {
  password: string
}


export default function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false)
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ChangePasswordProps>({
    resolver: yupResolver(newPassword),
  });
  const navigate = useNavigate();

  const handleSubmitNewPassword = async (data: ChangePasswordProps) => {
    if(!token) return
    setIsLoading(true)
    try {
      await ApiService.Clients.updatePasswordForgotPassword({
        password: data.password,
        token
      })
      openSuccessToast({ message: 'Senha alterada com sucesso!'})
      navigate(`${ROUTES.LOGIN}?section=login`);
    } catch (error) {
      openErrorToast({
        message: (error as ApiError).message || 'Ocorreu um erro ao alterar a senha',
      })
    } finally {
      setIsLoading(false)
    }
  };

  
  const inputProps = useCallback(
    (fieldName: keyof ChangePasswordProps) => {
      return { InputLabelProps: { shrink: !!watch(fieldName) } };
    },
    [watch]
  );

  if(isLoading) {
    return <LoadingFullPage/>
  }

  return (
   <>
     <Header />
     <Box
        component="main"
        sx={{             display: "flex",
        justifyContent: "center",
        alignItems: "center", backgroundColor: "var(--gray-1)", flexGrow: 1, py: 8 }}
      >
        <Container
          maxWidth={false}
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 3, maxWidth: '400px' }}>
        <form
          onSubmit={handleSubmit(handleSubmitNewPassword)}
        >
          <Card >
            <CardContent>
              <Grid container xs={12}>
                <Grid item xs={12}>
                <InputPassword
                fullWidth
                label="Nova senha"
                id="password"
                type="password"
                {...register("password")}
                inputProps={{
                  pattern: "^.{6,}$",
                  ...inputProps("password"),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
                </Grid>
              </Grid>

              <Box>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{
                    marginLeft: 'auto',
                    display: 'block',
                    marginTop: '1em',
                  }}
                >
                  Alterar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      </Box>
            </Box>

          </Box>
        </Container>
      </Box>
   </>
  )
}
