import styled from "styled-components";

interface Props {
  isChecked?: boolean;
}

export const Container = styled.div<Props>`
  position: relative;

  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  border-radius: 1rem;
  padding: 1rem;
  background: var(--gray-1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  overflow: hidden;
  border: ${(props) =>
    props.isChecked
      ? "3px solid var(--secondary-color)"
      : "3px solid var(--gray-1)"};

  
  .container-title{
    width: 100%;
    display: flex;
    justify-content: center;
    h3 {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--gray-5);
      margin-right: 1rem;
    }
  }

  span {
    font-size: 0.8755rem;
    font-weight: 400;
    color: var(--gray-4);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;

  }
  .tag-format {
    background: var(--primary-color);
    color: #fff;
    text-transform: uppercase;
    padding: 0.6rem;
  }

  .line {
    width: 100%;
    height: 0px;
    border: 1px dashed var(--gray-2);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-5);
  }

  .price {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
  }

  @media (min-width: 500px) {
    min-width: 336px; 
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  max-height: 30rem;
  img {
    max-width: 100%;
    max-height: 20rem;
    object-fit: cover;
  }
`;

export const ContainerPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: center;
`;

export const CheckButton = styled.button<Props>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  border: none;
  border-radius: 50%;
  background: ${(props) =>
    props.isChecked ? "var(--white)" : "var(--gray-2)"};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 0;

  div {
    width: 60%;
    height: 60%;
    border-radius: 100%;
    background: ${(props) =>
      props.isChecked ? "var(--secondary-color)" : "var(--gray-1)"};
  }
`;
