import React from 'react'
import { createPortal } from 'react-dom'
import { useDomReady } from 'hooks/use-dom-ready'

import * as Styled from './styles'

export type ModalProps = React.PropsWithChildren<{
  visible: boolean
  onClose?: () => void
  footer?: React.ReactNode
  style?: React.CSSProperties
}>

function ModalComponent({ onClose, children, footer, style }: Omit<ModalProps, 'visible'>) {
  return (
    <Styled.Overlay data-testid="modal_overlay">
      <Styled.Container data-testid="modal_container" style={style}>
        <Styled.CloseIcon data-testid="modal_close" onClick={onClose} />
        {children}

        {footer && <Styled.Footer>{footer}</Styled.Footer>}
      </Styled.Container>
    </Styled.Overlay>
  )
}

export function Modal({ visible, ...props }: ModalProps) {
  const domReady = useDomReady()

  if (!visible) return null

  if (!domReady) return null

  const portalElement = document.querySelector('body') as Element
  return createPortal(<ModalComponent {...props} />, portalElement)
}
