import { devicesMinWidth } from "constants/media-queries";
import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
  background: var(--gray-1);
`;

export const Content = styled.div`
  width: calc(95% - 4rem);
  padding: 2rem;
  background: var(--white);
  border-radius: 2rem;
`;

export const BoxCards = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  margin-bottom: 4rem;
  gap: 2rem;
  @media ${devicesMinWidth.laptop} {
    justify-items: flex-start;
  }

`;

export const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;
