export const ENDPOINTS = {
  LOGIN: "/commercial/public/clients/login",

  CONTRACTS: {
    GET_ALL: "/commercial/contracts",
    GET: (id: string) => `/commercial/contracts/view/${id}`,
    CREATE: "/commercial/contracts",
    UPDATE: (id: string) => `/commercial/contracts/update/${id}`,
    DELETE: (id: string) => `/commercial/contracts/delete/${id}`,
  },

  SERVICES: {
    GET_ALL: "/commercial/public/services",
    GET: (id: string) => `/commercial/public/services/view/${id}`,
    GET_NAMES: "/commercial/services/names",
    CREATE: "/commercial/services/create",
    UPDATE: (id: number) => `/commercial/services/update/${id}`,
    DELETE: (id: string) => `/commercial/services/delete/${id}`,
  },

  SERVICES_CATEGORIES: {
    GET_ALL: "/commercial/public/servicesCategories/categoriesAndServices",
    GET: (id: string) => `/commercial/public/servicesCategories/view/${id}`,
    GET_NAMES: "/commercial/servicesCategories/names",
    CREATE: "/commercial/servicesCategories/create",
    UPDATE: (id: number) => `/commercial/servicesCategories/update/${id}`,
    DELETE: (id: string) => `/commercial/servicesCategories/delete/${id}`,
  },

  COUPONS: {
    GET_ALL: "/commercial/discountCoupons",
    GET: (id: string) => `/commercial/discountCoupons/view/${id}`,
    GET_BY_COUPON_NAME: (coupon: string) =>
      `/commercial/discountCoupons/view/coupon-name/${coupon}`,
    CREATE: "/commercial/discountCoupons/create",
    UPDATE: (id: number) => `/commercial/discountCoupons/update/${id}`,
    DELETE: (id: string) => `/commercial/discountCoupons/delete/${id}`,
  },

  CLIENTS: {
    GET_ALL: "/commercial/clients",
    GET: (id: string) => `/commercial/clients/view/${id}`,
    GET_BY_CPF: (cpf: string) => `/commercial/public/clients/view/cpf/${cpf}`,
    SEND_MAIL_FORGOT_PASSWORD: `/commercial/public/clients/password/forgot`,
    UPDATE_PASSWORD_FORGOT_PASSWORD: `/commercial/public/clients/password/reset`,
    CREATE: "/commercial/public/clients",
    UPDATE: (id: string) => `/commercial/clients/update/${id}`,
    DELETE: (id: string) => `/commercial/clients/delete/${id}`,
    REFRESH_TOKEN: '/commercial/public/clients/refreshToken',
  },

  CLIENT_ORIGINS: {
    GET_OPTIONS: "/commercial/public/clientOrigins/options",
  },

  FORMS: {
    GET_ALL: "/commercial/forms",
    GET: (id: string) => `/commercial/forms/view/${id}`,
    CREATE: "/commercial/forms",
    UPDATE: (id: string) => `/commercial/forms/update/${id}`,
    DELETE: (id: string) => `/commercial/forms/${id}`,
  },

  PUBLIC_KEY: {
    GET: "/sessions/token",
  },
  PAGSEGURO_RESERVATION: {
    GET_SESSION: '/financial/pagseguro/reservation/session',
    GET_INSTALLMENTS: '/financial/pagseguro/reservation/getInstallments',
    CREATE_BANK_SLIP: '/financial/pagseguro/reservation/createBankSlip',
    CREATE_CREDIT_CARD: '/financial/pagseguro/reservation/createCreditCard',
    CREATE_CREDIT_PIX: '/financial/pagseguro/reservation/createPix',
    CANCEL_TRANSACTION: '/financial/pagseguro/reservation/cancelTransaction',
  },
};
