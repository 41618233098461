import React, { PropsWithChildren, ButtonHTMLAttributes } from 'react'

import * as Styled from './styles'

type ButtonProps = PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> & {
  dataTestId?: string
  uppercase?: boolean
  fullWidth?: boolean
  variant?: 'contained' | 'outlined'
}

export const Button = React.forwardRef(function ButtonComponent(
  { dataTestId, uppercase = true, fullWidth = false, variant = 'contained', ...props }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Styled.Button
      {...props}
      ref={ref}
      variant={variant}
      data-testid={dataTestId}
      uppercase={uppercase}
      fullWidth={fullWidth}
    />
  )
})
