import { INPUT_MASK } from "constants/masks";
import dayjs from "dayjs";
import * as yup from "yup";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

const clientSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório").min(11, 'Precisa ter no mínimo 11 caracteres'),
  cpf: yup.string().required("Campo obrigatório"),
  email: yup.string().required("Campo obrigatório"),
  birth_date: yup.string().transform((_, originalValue) => {
    return dayjs(originalValue).format(INPUT_MASK.DATE_EUA)
  }).required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  district: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  uf: yup.string().required("Campo obrigatório"),
  zip_code: yup.string().required("Campo obrigatório"),
  client_origin_id: yup.string().required("Campo obrigatório"),
  phone: yup.string(),
  cell_phone: yup.string(),
  password: yup.string().required("Campo obrigatório"),
});

const loginSchema = yup.object().shape({
  cpf: yup.string().test(
    'test-invalid-cpf',
    'cpf inválido',
    (cpf) =>  cpfValidator.isValid(cpf || '')).required("Campo obrigatório"),
  password: yup.string().required("Campo obrigatório"),
});

const forgotPasswordSchema = yup.object().shape({
  cpf: yup.string().required("Campo obrigatório"),
});

export { clientSchema, loginSchema, forgotPasswordSchema };
