export const INPUT_MASK = {
  CPF: "999.999.999-99",
  CNPJ: "99.999.999/9999-99",
  ZIP_CODE: "99999-999",
  CELL_PHONE: "(99) 99999-9999",
  PHONE: "(99) 9999-9999",
  DATE_LOCALE: "DD/MM/YYYY",
  DATE_EUA: "YYYY-MM-DD",
  TIME: "hh:mm:ss",
};

export const MONEY_MASK = (amount: number): string => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(amount);
};

export function isInvalidCpf(cpf: string | undefined): boolean {
  if (!cpf) return true;

  cpf = cpf.replace(/[^\d]/g, "");

  if (cpf.length !== 11) {
    return true;
  }

  const invalidCpfs = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
  ];

  if (invalidCpfs.includes(cpf)) {
    return true;
  }

  let sum = 0;
  let mod = 0;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) {
    mod = 0;
  }

  if (mod !== parseInt(cpf.substring(9, 10))) {
    return true;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) {
    mod = 0;
  }

  if (mod !== parseInt(cpf.substring(10, 11))) {
    return true;
  }

  return false;
}