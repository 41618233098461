import { AxiosError } from "axios";
import { ENDPOINTS } from "constants/endpoints";
import apiInstance from "services/api/instance";
import { handleErrorMessage } from "../errors";

export const getAll = async (): Promise<CouponsApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<CouponsApiResponse[]>(
      ENDPOINTS.COUPONS.GET_ALL
    );

    return getAllResponse.data;
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const get = async (id: string): Promise<CouponsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<CouponsApiResponse>(
      ENDPOINTS.COUPONS.GET(id)
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const getByCouponName = async (
  coupon: string
): Promise<CouponsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<CouponsApiResponse>(
      ENDPOINTS.COUPONS.GET_BY_COUPON_NAME(coupon)
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const create = async (
  coupon: CouponsApiRequest
): Promise<CouponsApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<CouponsApiResponse>(
      ENDPOINTS.COUPONS.CREATE,
      coupon
    );

    return creationResponse.data;
  } catch (creationError) {
    const axiosError = creationError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const update = async ({
  id,
  coupon,
}: {
  coupon: CouponsApiRequest;
  id: number;
}): Promise<CouponsApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<CouponsApiResponse>(
      ENDPOINTS.COUPONS.UPDATE(id),
      coupon
    );

    return updateResponse.data;
  } catch (updateError) {
    const axiosError = updateError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.COUPONS.DELETE(id)
    );

    return deleteResponse.data;
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};
