import { AxiosError } from "axios";
import { ENDPOINTS } from "constants/endpoints";
import apiInstance from "services/api/instance";
import { handleErrorMessage } from "../errors";


export const getOptions = async (): Promise<ClientOriginsOptionsServiceResponse[]> => {
  try {
    const getOptionsResponse = await apiInstance.get<ClientOriginsOptionsApiResponse[]>(
      ENDPOINTS.CLIENT_ORIGINS.GET_OPTIONS
    );

    return getOptionsResponse.data.map(item => ({
      label: item.name,
      value: item.value
    }));
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};
