import React, { InputHTMLAttributes, useState, useMemo, FormEvent } from 'react'
import InputMask from 'react-input-mask'
import EyeOpen from '@mui/icons-material/Visibility';

import EyeClosed from '@mui/icons-material/VisibilityOff';
import * as Styled from './styles'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { IMaskInput } from 'react-imask';

type InputProps = Omit<OutlinedInputProps, "inputComponent" | "id" | 'error'> & {
  dataTestId?: string
  label: string
  fullWidth?: boolean
  containerId?: string
  mask?: string
  error?: string
  id?: string
}

export const Input = React.forwardRef(function InputComponent(
  {
    dataTestId,
    label,
    fullWidth = false,
    type,
    containerId,
    mask,
    id,
    error,
    required,
    ...inputProps
  }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  const PasswordVisibility = useMemo<JSX.Element>(() => {
    if (type !== 'password') return <></>

    return passwordVisible ? (
      <EyeClosed data-testid="hide_password" onClick={() => setPasswordVisible(false)} />
    ) : (
      <EyeOpen data-testid="show_password" onClick={() => setPasswordVisible(true)} />
    )
  }, [passwordVisible, type])

  return (
    <FormControl fullWidth={fullWidth} error={!!error}>
      <InputLabel
        htmlFor={id}
        shrink={inputProps.value ? true : undefined}
        style={{
          backgroundColor: "white",
          padding: "0 5px",
        }}
      >
        {label}
      </InputLabel>

      {mask ? (
        <InputMask
              mask={mask}
           
              onChange={inputProps.onChange}
              disabled={inputProps.disabled}
            >
              {/* @ts-ignore */}
              {(props: InputHTMLAttributes<HTMLInputElement>) => {
                const propsCopy = { ...inputProps }
                delete propsCopy.onBlur
    
                return (
<OutlinedInput
        {...inputProps}
        id={id}
        
           
        
      />
                )
              }}
            </InputMask>
      //   <OutlinedInput
      //   {...inputProps}
      //   id={id}
      //   inputComponent={({ onChange, ref, ...inputComponentProps }) => {
      //     return (
      //       <IMaskInput
      //         {...inputComponentProps}
      //         mask={mask}
      //         definitions={{ "#": /[1-9]/ }}
      //         inputRef={ref}
      //         overwrite
      //         onAccept={(_value, _maskReference, event) => {
      //           if (!event) return;

      //           onChange?.(
      //             event as unknown as FormEvent<
      //               HTMLInputElement | HTMLTextAreaElement
      //             >
      //           );

      //           setTimeout(() => {
      //             const currentInput = document.querySelector(
      //               `input[id="${id}"]`
      //             ) as HTMLInputElement;

      //             currentInput?.focus();
      //           }, 50);
      //         }}
      //       />
      //     );
      //   }}
      // />
      ) : (
<OutlinedInput
        ref={ref}
        id={id}
        // type={passwordVisible ? "text" : "password"}
        fullWidth={fullWidth}
        label={label}
        // endAdornment={
        //   <InputAdornment position="end">
        //     <IconButton
        //       aria-label="Alterar visibilidade"
        //       edge="end"
        //       // onClick={togglePasswordVisibility}
        //       onMouseDown={(event) => {
        //         event.preventDefault();
        //       }}
        //     >
        //       {PasswordVisibility}
        //     </IconButton>
        //   </InputAdornment>
        // }
        {...inputProps}
      />
      )}

      {/* <FormHelperText id={`${id}"-helper-text"`}>{helperText}</FormHelperText> */}
    </FormControl>
    // <Styled.Container fullWidth={fullWidth} id={containerId}>
    //   <Styled.Label error={error}>
    //     {label}
    //     {required && <Styled.Required>*</Styled.Required>}
    //   </Styled.Label>

    //   {mask ? (
    //     <InputMask
    //       mask={mask}
    //       value={inputProps.value}
    //       onChange={inputProps.onChange}
    //       disabled={inputProps.disabled}
    //     >
    //       {/* @ts-ignore */}
    //       {(props: InputHTMLAttributes<HTMLInputElement>) => {
    //         const propsCopy = { ...inputProps }
    //         delete propsCopy.onBlur

    //         return (
    //           <OutlinedInput
     
         
             

    //           />
    //           // <Styled.Input
    //           //   ref={ref}
    //           //   {...props}
    //           //   type="tel"
    //           //   data-testid={dataTestId}
    //           //   isPassword={false}
    //           //   error={error}
    //           //   {...propsCopy}
    //           // />
    //         )
    //       }}
    //     </InputMask>
    //   ) : (
    //     <Styled.Input
    //       ref={ref}
    //       {...inputProps}
    //       type={type === 'password' && passwordVisible ? 'text' : type}
    //       isPassword={type === 'password'}
    //       data-testid={dataTestId}
    //       error={error}
    //     />
    //   )}

    //   {PasswordVisibility}

    //   {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
    // </Styled.Container>
  )
})
