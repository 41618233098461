import { AxiosError } from "axios";
import { ENDPOINTS } from "constants/endpoints";
import apiInstance from "services/api/instance";
import { handleErrorMessage } from "../errors";

export const getAll = async (): Promise<ContractsApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<ContractsApiResponse[]>(
      ENDPOINTS.CONTRACTS.GET_ALL
    );

    return getAllResponse.data;
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const get = async (id: string): Promise<ContractsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<ContractsApiResponse>(
      ENDPOINTS.CONTRACTS.GET(id)
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const create = async (
  contract: ContractsApiRequest
): Promise<ContractsApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<ContractsApiResponse>(
      ENDPOINTS.CONTRACTS.CREATE,
      contract
    );

    return creationResponse.data;
  } catch (creationError) {
    const axiosError = creationError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const update = async ({
  id,
  contract,
}: {
  contract: ContractsApiRequest;
  id: string;
}): Promise<ContractsApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<ContractsApiResponse>(
      ENDPOINTS.CONTRACTS.UPDATE(id),
      contract
    );

    return updateResponse.data;
  } catch (updateError) {
    const axiosError = updateError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.CONTRACTS.DELETE(id)
    );

    return deleteResponse.data;
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};
