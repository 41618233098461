import styled from 'styled-components'

import { devicesMinWidth } from 'constants/media-queries'

export const ActivityPaymentContainer = styled.div`
  width: 100%;

  h2 {
    grid-column: 1/-1;
  }

  #pix-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      padding: 30px 0px;
      width: 100%;
      max-width: 400px;
    }
  }

  #pay-with-credit-card,
  #generate-bank-slip,
  #other-ownership,
  #address-subtitle {
    grid-column: 1/-1;
  }

  #address-subtitle {
    color: #c4c4c4;
  }

  .subtitle1 {
    color: #c4c4c4;
  }

  #pay-with-credit-card {
    @media ${devicesMinWidth.tablet} {
      max-width: 200px;
    }
  }

  #generate-bank-slip {
    @media ${devicesMinWidth.tablet} {
      max-width: 160px;
    }
  }
`

export const PaymentTypeLabel = styled.h2`
  display: flex;
  font-family: var(--rubik-font);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  width: 100%;

  @media ${devicesMinWidth.laptop} {
    font-size: 30px;
    line-height: 55px;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
`

export const NewSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  width: 100%;

  @media ${devicesMinWidth.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${devicesMinWidth.laptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > div {
    height: auto;
    max-width: 300px;
  }
`

export const PaymentMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  padding:20px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 8px;
  &.selected {
    border: 1px solid #FF9C5E;
    background-color: #FFF4ED;
  }
  :hover {
    border: 1px solid #FF9C5E;
    background-color: #FFF4ED;
  }
`

export const CopyPastPIX = styled.div`
  position: relative;
  width: 100%;
  max-width: 700px;
  p {
    word-wrap: break-word;
  }
  button {
    display: block;
    margin: auto;
    cursor: pointer;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
  background: var(--gray-1);

`;

export const Content = styled.div`
  width: calc(95% - 4rem);
  padding: 2rem;
  background: var(--white);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 2rem 0;
  border-top: 1px solid rgba(0,0,0,0.2);
`;

export const ContainerImageService = styled.div`
  display: flex;
  justify-content: center;
  max-height: 30rem;
  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 30rem;
    border-radius: 8px;
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  img {
    
    object-fit: cover;
max-width: 100%;
    border-radius: 8px;
  }
`;

export const ContainerServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
    
  .container-interest-free-installments{
    font-size: 0.9rem;
    color: var(--primary-color);
  }
  h3 {
    font-size: 2rem;
    font-weight: 500;
    color: var(--gray-5);
    margin-right: 1rem;
  }
`;
export const ContainerPrice = styled.div`
  display: flex;

  .price {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
    margin: 1rem 0;
  }
`;

export const ContainerServiceBuy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

export const BoxCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  justify-items: center;
  margin-bottom: 4rem;
  gap: 2rem;
`;

export const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;

