import { useState, useCallback, useEffect, useRef, useMemo } from 'react'

import { Input } from 'components/Input'
import { Button } from 'components/Button'
import Select from 'components/Select'
import { SELECT_BRAZILIAN_STATES } from 'constants/brazilian-states'
import usePagseguro from 'hooks/usePagseguro/usePagseguro'
import { ApiService } from 'services/api'
import TextWithLabel from 'components/TextWithLabel'
import { useGlobalState } from 'contexts/global-state'
import { useParams } from 'react-router-dom'
import { ModalConfirm } from 'components/ModalConfirm';
import { ModalLoading } from 'components/ModalLoading';
import Text from 'components/Text';
import Header from 'components/Header'
import { Grid } from "@mui/material";
import { ActivityPaymentContainer, Container, Content, CopyPastPIX, Divider, NewSection, PaymentMethod, PaymentTypeLabel } from './styles'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PixIcon from '@mui/icons-material/Pix';
import { INPUT_MASK, MONEY_MASK } from 'constants/masks'
import { CacheService } from 'services/cache';
import { encodedBase64 } from 'utils/base64';
import dayjs from 'dayjs'
import LoadingFullPage from 'components/LoadingFullPage'
import { BRL } from 'utils/mask'
const getNumbers = (value: string) => value.replace(/\D/g, '')

enum PaymentType {
  BANK_SLIP = 'bankSlip',
  CREDIT_CARD = 'creditCard',
  PIX = 'pix',
}

type Installment = {
  value: string
  label: string
}

type CreditCardPayment = {
  number: string
  securityCode: string
  expiration: string
  parcels: string
  name: string
  cpf: string
  birthdate: string
  phone: string
  zipCode: string
  address: string
  addressNumber: string
  complement: string
  neighborhood: string
  federativeUnit: string
  city: string
}

type EncryptedCreditCardRequest = {
  cardNumber: string
  brand: string
  cvv: string
  expirationMonth: string
  expirationYear: string
  name: string
}


const encryptedCard = (card: EncryptedCreditCardRequest) => {
  if(!window) {
    console.log('Window not found')
    return
  }
  if(!window?.PagSeguro?.encryptCard) {
    console.log('Not found sdk')
    return
  }
  const responseEncryptedCard = window.PagSeguro.encryptCard({
    publicKey: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAttotpk26NRAZ0cE5qw0/3NRzKZj13GLv1oq2bg8wzIR2XoULNdOX29xzQcg17BXtezCcsyPAxt38+Tm7PeJozRdo+d0novs8jjOVxOfOzD31s8pmD5egzn8CgVlQhni3PtOMcWsdaZzljWoZwvKVAEa7W0SdRgvhGDAymwTqNeCf4l2GFo+6+JA6og8B60uOP7qZpI3JTd4b3HJQuMfyyf+klUJ/KCbdXMPH0dUA33wl1qUiJl3DcYB57DG1UYMl4be2ykeI0MW/Ip2Ba7ZTIjfTIQ+k0PZPnFdjJXdtGiMIA1+phNdwGJVKSkGYFbuPMacPmlxpZtRAcgCyOVgcFwIDAQAB`,
    holder: card.name,
    number: card.cardNumber,
    expMonth: card.expirationMonth,
    expYear: card.expirationYear,
    securityCode: card.cvv
  });
  return responseEncryptedCard.encryptedCard
}


const generateLinkRedirectToClientDashboard = () => {
  const storageClient = JSON.parse(CacheService.client.getCurrentClient() || '')
  if(!storageClient) {
    return ''
  }
  const passBase64 = encodedBase64(JSON.stringify({ token: storageClient.token }))
  return `${process.env.REACT_APP_WEB_URL_CLIENT}?pass=${passBase64}`
}

export default function Payment() {
  const { client, openErrorToast, openInfoToast, openSuccessToast } = useGlobalState()
  const { id: contractId } = useParams()


  const {
    setSenderHash,
    createBankSlip,
    createCreditCard,
    createPix,
    setType,
  } = usePagseguro()

  const [brand, setBrand] = useState('')
  const cardBin = useRef('')
  const [installments, setInstallments] = useState<Installment[]>()
  const [linkBankSlip, setLinkBankSlip] = useState('')
  const [contract, setContract] = useState<ContractsApiResponse | null>()
  const [pixResponse, setPixResponse] = useState<PagseguroCreatePixResponse>()

  const totalValueNumber = useMemo(() => {
    if((contract?.service?.quantity || 0) > 1) {
      return Number(contract?.service.value)
    }
    return Number(contract?.service.value) * (contract?.forms.length || 0)
  }, [contract?.forms.length, contract?.service?.quantity, contract?.service.value])
  const totalValue = useMemo(() => {
    return MONEY_MASK(totalValueNumber)
  }, [totalValueNumber])


  const pixInterval = useRef<NodeJS.Timer>()

  const [modalPix, setModalPix] = useState<boolean>(false)
  const [pixPaid, setPixPaid] = useState(false)

  const handleLoadContract = useCallback(async () => {
    if (!contractId) {
      return
    }
    const responseContract = await ApiService.Contracts.get(contractId as string)
    setContract(responseContract)
  }, [contractId])

  const handleLoadInstallments = useCallback(async ({
    credit_card_bin,
    max_installments,
    max_installments_no_interest,
    payment_methods,
    show_seller_fees,
    contract_id,
    value
  }: PagseguroGetInstallmentsRequest) => {
    const responseContract = await ApiService.Pagseguro.getInstallments({
      credit_card_bin,
      max_installments,
      contract_id,
      max_installments_no_interest,
      payment_methods,
      show_seller_fees,
      value
    })
    const brand = Object.keys(responseContract.payment_methods.credit_card)[0]
    const installmentsPlans = responseContract.payment_methods.credit_card[brand].installment_plans
    setBrand(brand)
    setInstallments(installmentsPlans.map((installment, index) => ({
      label: `${installment.installments} de R$ ${BRL(installment.installment_value).format()} ${
        index === 0
          ? '(à vista)'
          : installment.interest_free
          ? '(sem juros)'
          : `(por R$ ${BRL(installment.amount.value).format()})`
      }`,
      value: `${installment.installments}-${installment.installment_value}-${Number(contract?.total_value)}`,
    })))
  }, [contract])

  useEffect(() => {
    handleLoadContract()
  }, [handleLoadContract])

  useEffect(() => {
    return () => {
      clearInterval(pixInterval.current)
    }
  }, [])

  useEffect(() => {
    if (contract?.payment_gateway_id) {
      setType({
        type: 'default',
        gateway_id: contract.payment_gateway_id,
      })
      setTimeout(() => {
        setSenderHash()
      }, 1000)
    }
  }, [contract, setSenderHash, setType])

  const [otherOwnership] = useState<boolean>(false)

  const [creditCardValues, setCreditCardValues] = useState<CreditCardPayment>(
    {} as CreditCardPayment,
  )

  const [submited, setSubmited] = useState<boolean>(false)

  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.CREDIT_CARD)
  const [loadingPayment, setLoadingPayment] = useState(false)

  const payWithCreditCard = useCallback(
    (creditCardData: CreditCardPayment) => {
      console.log(client)
      setSubmited(true)
      if (
        !creditCardData.number ||
        !creditCardData.name ||
        !creditCardData.cpf ||
        !creditCardData.securityCode ||
        !creditCardData.expiration
        // || !creditCardData.parcels
      ) {
        return
      }
      if (otherOwnership) {
        if (
          !creditCardData.address ||
          !creditCardData.addressNumber ||
          !creditCardData.complement ||
          !creditCardData.neighborhood ||
          !creditCardData.city ||
          !creditCardData.federativeUnit ||
          !creditCardData.zipCode
        ) {
          return
        }
      }
      setLoadingPayment(true)
      let billingAddress
      if (otherOwnership) {
        billingAddress = {
          street: creditCardData.address,
          number: creditCardData.addressNumber,
          complement: creditCardData.complement,
          district: creditCardData.neighborhood,
          city: creditCardData.city,
          state: creditCardData.federativeUnit,
          country: 'BRA',
          postalCode: creditCardData.zipCode,
        }
      }
      createCreditCard({
        otherOwnership,
        entity_id: String(contractId),
        card: {
          cardNumber: creditCardData.number,
          brand,
          cvv: creditCardData.securityCode,
          expirationMonth: creditCardData.expiration.split('/')[0],
          expirationYear: '20'.concat(creditCardData.expiration?.split('/')[1]),
        },
        creditCardEncrypted: encryptedCard({
          brand,
          cardNumber: creditCardData.number,
          cvv: creditCardData.securityCode,
          expirationMonth: creditCardData.expiration.split('/')[0],
          expirationYear: '20'.concat(creditCardData.expiration?.split('/')[1]),
          name: creditCardData.name
        }),
        installment: {
          quantity: '1',
          value: 1,
          total: 1
          // quantity: creditCardData.parcels?.split('-')[0],
          // value: Number(creditCardData.parcels?.split('-')[1]),
          // total: Number(creditCardData.parcels?.split('-')[2]),
        },
        holder: {
          name: creditCardData.name,
          birthDate: dayjs(client?.birth_date, INPUT_MASK.DATE_EUA).format(INPUT_MASK.DATE_LOCALE),
          phone: {
            areaCode: getNumbers(client?.cell_phone || '').substring(0, 2),
            number: getNumbers(client?.cell_phone || '').substring(2),
          },
          documents: {
            document: {
              type: 'CPF',
              value: creditCardData.cpf.replace(/\D/g, ''),
            },
          },
        },
        billingAddress,
      })
        .then(() => {
          setLoadingPayment(false)
          openInfoToast({
            message: 'Aguardando confirmação do pagamento.'
          })

          window.location.href =       generateLinkRedirectToClientDashboard()
        })
        .catch((error) => {
          console.log(error)
          let message = error.message
          if(!message?.length) {
            message = 'Ocorreu um erro ao efetuar o pagamento, por favor, tente novamente'
          }
          setLoadingPayment(false)
          openErrorToast({
            message
          })
        })
    },
    [client, otherOwnership, createCreditCard, contractId, brand, openInfoToast, openErrorToast],
  )

  const generateBankSlip = useCallback(async () => {
    setLoadingPayment(true)
    createBankSlip(String(contractId))
      .then((createSlipResponse) => {
        setLinkBankSlip(createSlipResponse)
        setLoadingPayment(false)
        openInfoToast({
          message: 'Aguardando confirmação do pagamento.'
        })
        const storageClient = encodedBase64(CacheService.client.getCurrentClient() || '')
        window.location.href = `http://localhost:3001?auth=${storageClient}`
      })
      .catch((error) => {
        setLoadingPayment(false)
        openErrorToast(error.message)
        setLoadingPayment(false)
      })
  }, [createBankSlip, contractId, openInfoToast, openErrorToast])

  const validate = (value: string) => {
    return submited && !value ? 'Este campo é obrigatório' : ''
  }

  const generatePix = useCallback(async () => {
    setModalPix(false)
    setLoadingPayment(true)
    await createPix(String(contractId))
      .then((pixResponse) => {
        setLoadingPayment(false)
        setPixResponse(pixResponse)
        const intervalId = setInterval(() => {
          if (pixResponse) {
            ApiService.Contracts.get(String(contractId)).then(
              (contractResponse) => {
                if (contractResponse.status === 'payment_confirmed') {
                  setPixPaid(true)
                  openSuccessToast({
                    message: 'Pagamento confirmado. Reserva confirmada!'
                  })
                  window.location.href = generateLinkRedirectToClientDashboard()
                  clearInterval(intervalId)
                }
              },
            )
          }
        }, 10000)
        pixInterval.current = intervalId
      })
      .catch((error) => {
        setLoadingPayment(false)
        openErrorToast(error.message)
      })
  }, [createPix, contractId, openSuccessToast, openErrorToast])



  if (!contract  || !contract?.forms) {
    return <LoadingFullPage />
  }

  return (
  
<>
<Container>
<Header />
<Content>

      <NewSection style={{ marginBottom: '1rem' }}>
       <h1 style={{ fontSize: '1.5rem' }}>Resumo</h1>
      </NewSection>


      <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2} marginTop={1}>
        <Grid item xs md={12}>
        <h1 style={{ fontSize: '1.2rem' }}>Aplicantes</h1>
        </Grid>
        {contract?.forms?.map(form => (
          <>
        <Grid item xs md={4}>
           <TextWithLabel label="Nome" value={form?.applicant_name} /> 
           </Grid>

           <Grid item xs md={2}>
           </Grid>

           <Grid item xs md={4}>
           </Grid>

           <Grid item xs md={2}>
           </Grid>

           <Divider />
        
          </>
        ))}
         <Grid item md={4}>
         <TextWithLabel label="Serviço" value={contract?.service?.name} />
       
           </Grid>

           <Grid item md={2}>
           <TextWithLabel
          label="Valor do serviço"
          value={MONEY_MASK(Number(contract?.service?.value))}
        />
           </Grid>

           <Grid item md={4}>
           <TextWithLabel
          label="Aplicantes"
          value={contract?.forms?.length.toString()}
        />
           </Grid>

           <Grid item md={2}>
           <TextWithLabel label="Valor total" value={totalValue} /> 
           </Grid>

           <Divider />
      </Grid>

      <ActivityPaymentContainer>
        <NewSection>
          <h2 className="subtitle">Forma de pagamento</h2>
          <PaymentMethod
            className={paymentType === PaymentType.CREDIT_CARD ? 'selected' : ''}
            onClick={() => setPaymentType(PaymentType.CREDIT_CARD)}
          >
            <CreditCardIcon sx={{ color: '#ff6606' }}/>
            <h3>CARTÃO DE CRÉDITO</h3>
          </PaymentMethod>
          <PaymentMethod
            className={paymentType === PaymentType.PIX ? 'selected' : ''}
            onClick={() => {
              setPaymentType(PaymentType.PIX)
              setModalPix(true)
            }}
          >
            <PixIcon sx={{ color: '#ff6606' }}/>
            <h3>PIX</h3>
          </PaymentMethod>
        </NewSection>

        {paymentType === PaymentType.CREDIT_CARD && (
          <NewSection>
            <Input
              fullWidth
              label={`Número${brand ? ` (${brand})` : ''}`}
              mask="9999 9999 9999 9999"
              onChange={async (event) => {
                const value = event.target.value.replace(/[ ]|[_]/g, '')
                setCreditCardValues((prevState) => ({
                  ...prevState,
                  number: value,
                }))
                // const hasInstalls = value.substring(0, 6) === cardBin.current
     
                if (value.length >= 6) {
                  cardBin.current = value.substring(0, 6)
                  
                  handleLoadInstallments({
                    credit_card_bin: cardBin.current,
                    payment_methods: 'CREDIT_CARD',
                    value: totalValueNumber.toString(),
                    max_installments: '12',
                    contract_id: contractId as string
                  })
                } else {
                  setBrand('')
                  setInstallments([])
                }
              }}
              error={validate(creditCardValues.number)}
            />

            <Input
              fullWidth
              label="Nome impresso no cartão"
              onChange={(event) =>
                setCreditCardValues((prevState) => ({
                  ...prevState,
                  name: event.target.value,
                }))
              }
              error={validate(creditCardValues.name)}
              onFocus={() => setSenderHash()}
            />

            <Input
              fullWidth
              label="CPF"
              mask="999.999.999-99"
              onChange={(event) =>
                setCreditCardValues((prevState) => ({
                  ...prevState,
                  cpf: event.target.value,
                }))
              }
              error={validate(creditCardValues.cpf)}
            />

            <Input
              fullWidth
              label="Código segurança"
              mask="999"
              onChange={(event) =>
                setCreditCardValues((prevState) => ({
                  ...prevState,
                  securityCode: event.target.value,
                }))
              }
              error={validate(creditCardValues.securityCode)}
            />

            <Input
              fullWidth
              label="Data de expiração"
              mask="99/99"
              onChange={(event) =>
                setCreditCardValues((prevState) => ({
                  ...prevState,
                  expiration: event.target.value,
                }))
              }
              error={validate(creditCardValues.expiration)}
            />

            <Select
              id='installments'
              formControlMargin="none"
              fullWidth
              label="Parcelas"
              onChange={(event) =>
                setCreditCardValues((prevState) => ({
                  ...prevState,
                  parcels: (event.target as HTMLSelectElement).value,
                }))
              }
              options={installments || []}
              value={creditCardValues.parcels}
               error={!!validate(creditCardValues.parcels)}
            />

            {otherOwnership && (
              <>
                <Input
                  fullWidth
                  label="Telefone"
                  mask="(99) 9 9999-9999"
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      phone: event.target.value,
                    }))
                  }
                  error={validate(creditCardValues.phone)}
                />
                <h4 id="address-subtitle">Endereço de cobrança</h4>

                <Input
                  fullWidth
                  label="CEP"
                  mask="99999-999"
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      zipCode: event.target.value,
                    }))
                  }
                  error={validate(creditCardValues.zipCode)}
                />

                <Input
                  fullWidth
                  label="Endereço"
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      address: event.target.value,
                    }))
                  }
                  error={validate(creditCardValues.address)}
                />

                <Input
                  fullWidth
                  label="Número"
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      addressNumber: event.target.value,
                    }))
                  }
                  error={validate(creditCardValues.addressNumber)}
                />

                <Input
                  fullWidth
                  label="Complemento"
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      complement: event.target.value,
                    }))
                  }
                  error={validate(creditCardValues.complement)}
                />

                <Input
                  fullWidth
                  label="Bairro"
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      neighborhood: event.target.value,
                    }))
                  }
                  error={validate(creditCardValues.neighborhood)}
                />

                <Input
                  fullWidth
                  label="Cidade"
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      city: event.target.value,
                    }))
                  }
                  error={validate(creditCardValues.city)}
                />

                <Select
                  fullWidth
                  label="UF"
                  options={[{ value: '', label: 'Selecione' },...SELECT_BRAZILIAN_STATES]}
                  id='1'
                  onChange={(event) =>
                    setCreditCardValues((prevState) => ({
                      ...prevState,
                      federativeUnit: (event.target as HTMLSelectElement).value,
                    }))
                  }
                   error={!!validate(creditCardValues.federativeUnit)}
                />
              </>
            )}

<Button
              disabled={loadingPayment}
              id="pay-with-credit-card"
              onClick={() => payWithCreditCard(creditCardValues)}
            >
              Efetuar pagamento
            </Button>
          </NewSection>
        )}

        {paymentType === PaymentType.BANK_SLIP && (
          <>
            <PaymentTypeLabel>Boleto</PaymentTypeLabel>
            <Button
              disabled={loadingPayment}
              id="generate-bank-slip"
              onClick={generateBankSlip}
              type="button"
            >
              Gerar boleto
            </Button>
            {linkBankSlip && (
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  window.open(linkBankSlip, '_blank')
                }}
              >
                Baixar boleto
              </Button>
            )}
          </>
        )}

        {paymentType === PaymentType.PIX && (
          <>
            <div id="pix-container">
              
              {pixResponse && (
                <>
                  <img src={pixResponse.qr_codes[0].links[0].href} alt="qr_code" />
                  <CopyPastPIX>
                    <h4>Por código copia e cola</h4>
                    <p>{pixResponse?.qr_codes[0].text}</p>
                    <Button
                      onClick={() => {
                        const copyText = pixResponse?.qr_codes[0].text
                        const el = document.createElement('textarea')
                        el.value = copyText
                        el.setAttribute('readonly', '')
                        document.body.appendChild(el)
                        el.select()
                        document.execCommand('copy')
                        document.body.removeChild(el)
                        // toast.success('código copiado')
                      }}
                    >
                      Copiar código
                    </Button>
                  </CopyPastPIX>

                  {pixPaid ? <p>Pago</p> : <p>Aguardando pagamento...</p>}
                </>
              )}
            </div>
          </>
        )}
      </ActivityPaymentContainer>


      

  
</Content>
</Container>
      <ModalConfirm
      visible={modalPix}
      onClose={() => setModalPix(false)}
      onAccept={() => generatePix()}
      footer={undefined}
    >
      <div style={{ maxWidth: '600px', lineHeight: '32px' }} >
        <p>Prezado cliente!</p>
        <p>Informamos que seu pedido foi registrado com sucesso, e agradecemos por escolher o Pix como forma de pagamento.</p>
        <p>Nossa chave CNPJ é: 53.095.065/0001-24 (Pode aparecer nome de Intervisa ou Vistos e Viagens). Por favor, envie o comprovante de pagamento para o número de <a style={{ marginLeft: '5px',color: '#FC741D' }} href='https://api.whatsapp.com/send?phone=31984751680&text=Olá, acabei de fazer o pagamento por pix e aqui esta o comprovante'>WhatsApp: 31 984751680.</a></p>
        <p>Após a confirmação do pagamento, atualizaremos o status do seu pedido. Uma vez atualizado, você poderá acessar sua área de cliente e prosseguir com o preenchimento do formulário online.</p>

        <p>Em caso de dúvidas, não hesite em entrar em contato conosco.</p>

        <p>Atenciosamente</p>
        <p>Vistos e Viagens</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
      <Button
              onClick={() => {
                window.location.href = generateLinkRedirectToClientDashboard()
              }}
            >
              Efetuei o pagamento
            </Button>
      </div>
    </ModalConfirm>

    <ModalLoading visible={loadingPayment}>
      <Text>Estamos Processando seu pagamento</Text>
    </ModalLoading>
    </>
  )
}
