import styled from "styled-components";

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
  background: var(--gray-1);


`;

export const Content = styled.div`
  width: calc(95% - 4rem);
  padding: 2rem;
  background: var(--white);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 2rem 0;
  border-top: 1px solid rgba(0,0,0,0.2);
`;

export const ContainerImageService = styled.div`
  display: flex;
  justify-content: center;
  max-height: 30rem;
  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 30rem;
    border-radius: 8px;
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  img {
    object-fit: cover;
    max-width: 100%;
    border-radius: 8px;
  }
`;

export const ContainerServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
    
  .container-interest-free-installments{
    font-size: 0.9rem;
    color: var(--primary-color);
  }
  h3 {
    font-size: 2rem;
    font-weight: 500;
    color: var(--gray-5);
    margin-right: 1rem;
  }
`;
export const ContainerPrice = styled.div`
  display: flex;

  .price {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
    margin: 1rem 0;
  }
`;

export const ContainerServiceBuy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

export const ContainerResume = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const BoxCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  justify-items: center;
  margin-bottom: 4rem;
  gap: 2rem;
`;

export const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;

export const NoticeModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  iframe {
    width: 100%;
    height: 315px;
  }
`
