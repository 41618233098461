import { Container } from "./styles";
import React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "../../assets/twitter.png";


const SocialIcon = ({ link, icon }: { link: string; icon: React.ReactNode }) => {
  return (
    <div style={{ textDecoration: 'none' }}>
      <a href={link} target="_blank" rel="noreferrer" style={{ color: "#000"}}>
        {icon}
      </a>
    </div>
  )
}
export default function Footer() {
  return (
    <Container>
      <div className="container-social">
        <h3>Mantenha-se conectado</h3>
        <div className="container-logo">
          <SocialIcon icon={<InstagramIcon />} link="https://instagram.com/assessoria.vistoseviagens" />
          <SocialIcon icon={<FacebookIcon />} link="https://www.facebook.com/vistoseviagensbh"/>
          <SocialIcon icon={<YouTubeIcon />} link="https://www.youtube.com/@VistosViagens"/>
          <SocialIcon icon={<img src={TwitterIcon} style={{ width: 'auto', height: '16px' }} alt="twitter logo" />} link="https://www.twitter.com/ViagensVistos"/>
        </div>
      </div>
    </Container>
  );
}
