import { useState } from "react";
import { Modal } from "@mui/material";
import { ContainerImage } from "./style";
import HideImageIcon from '@mui/icons-material/HideImage';
interface ImageProps {
  url: string
  alt?: string
  stretch?: boolean
}

export default function Image({ url, alt,stretch }: ImageProps) {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
    <ContainerImage stretch={stretch}>
      {!!url ? <img src={url} alt={alt} onClick={() => setOpenModal(true)} style={{ cursor: 'pointer'}} /> : (
        <HideImageIcon/>
      )}
    </ContainerImage>
    {openModal && (
      <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <img src={url} alt={alt} style={{  maxWidth: '90%', maxHeight: '90%'}} />
    </Modal>
    )}
    </>
  );
}
