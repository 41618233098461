import currency from "currency.js"

export function genericMaskWithTwoZeroWithPoint(value: string): string {
  let newValue = value
  // const integer = newValue?.split('.')[0]

  newValue = newValue?.replace(/\D/g, '')

  newValue = newValue?.replace(/^[0]+/, '')

  if (newValue?.length <= 2) {
    if (newValue?.length === 1) newValue = '0,0' + newValue

    if (newValue?.length === 2) newValue = '0,' + newValue
  } else {
    newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2')
    newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.')
  }

  return newValue
}

export function convertValueMaskInNumberWithTwoZero(value: string): number {
  let newValue = value.replace('.', '').replace(',', '')
  const integer = newValue.split('.')[0]

  newValue = newValue.replace(/\D/g, '')

  newValue = newValue.replace(/^[0]+/, '')

  if (newValue.length <= 2 || !integer) {
    if (newValue.length === 1) newValue = '0,0' + newValue

    if (newValue.length === 2) newValue = '0,' + newValue
  } else {
    newValue = newValue.replace(/^(\d{1,})(\d{2})$/, '$1,$2')
  }
  newValue = newValue.replace(',', '.')

  return parseFloat(newValue)
}

export function convertValueMaskInNumber(value: string): number {
  let newValue = value
  const integer = newValue.split('.')[0]

  newValue = newValue.replace(/\D/g, '')

  newValue = newValue.replace(/^[0]+/, '')

  if (newValue.length <= 3 || !integer) {
    if (newValue.length === 1) newValue = '0,00' + newValue

    if (newValue.length === 2) newValue = '0,0' + newValue

    if (newValue.length === 3) newValue = '0,' + newValue
  } else {
    newValue = newValue.replace(/^(\d{1,})(\d{3})$/, '$1,$2')
  }
  newValue = newValue.replace(',', '.')

  return parseFloat(newValue)
}

export function zipCodeMask(v: string): string {
  if (!v) return ''
  v = v.replace(/[^\d]/g, '') // Remove tudo o que não é dígito
  // Limita a quantidade de dígitos para 8
  v = v.slice(0, 8)

  v = v.replace(/^(\d{5})(\d)/g, '$1-$2') // Coloca hífen entre o quinto e o sexto dígitos
  return v
}

export const dateMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

export const timeMask = (value: string) => {
  if (!value) return
  return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1:$2')
}

export function phoneMask(v: string): string {
  if (!v) return ''

  // Remove tudo que não é dígito
  v = v.replace(/\D/g, '')

  // Limita a quantidade de dígitos para 11
  v = v.slice(0, 11)

  // Aplica a máscara
  v = v.replace(/^(\d{2})(\d)/, '($1) $2')
  v = v.replace(/(\d)(\d{4})$/, '$1-$2')

  return v
}

export const cnpjMask = (v: string) => {
  if (!v) return ''

  // Remove tudo o que não é dígito
  v = v.replace(/\D/g, '')

  // Limita a quantidade de dígitos para 14
  v = v.slice(0, 14)

  // Aplica a máscara do CNPJ
  v = v.replace(/^(\d{2})(\d)/, '$1.$2')
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
  v = v.replace(/(\d{4})(\d)/, '$1-$2')

  return v
}

export const cpfMask = (v: string) => {
  if (!v) return ''
  v = v.replace(/\D/g, '') // Remove tudo o que não é dígito
  // Limita a quantidade de dígitos para 11
  v = v.slice(0, 11)
  v = v.replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto entre o terceiro e o quarto dígitos
  // de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2') // Coloca um hífen entre o terceiro e o quarto dígitos
  return v
}

export function yearMask(v: string): string {
  if (!v) return ''

  // Remove tudo que não é dígito
  v = v.replace(/\D/g, '')

  // Limita a quantidade de dígitos para 4
  v = v.slice(0, 4)

  return v
}

export function ageMask(v: string): string {
  if (!v) return ''

  // Remove tudo que não é dígito
  v = v.replace(/\D/g, '')

  // Limita a quantidade de dígitos para 4
  v = v.slice(0, 2)

  return v
}

export function bankCodeMask(v: string): string {
  if (!v) return ''

  // Remove tudo que não é dígito
  v = v.replace(/\D/g, '')

  // Limita a quantidade de dígitos para 4
  v = v.slice(0, 3)

  return v
}

export function creditCardExpirationMask(v: string): string {
  if (!v) return ''

  // Remove caracteres que não são números ou barras
  v = v.replace(/[^\d/]/g, '')

  // Limita a quantidade de caracteres para 4
  v = v.slice(0, 5)

  // Aplica a máscara
  if (v.length > 2) {
    v = v.replace(/^(\d{2})(\d)/, '$1/$2')
  }

  return v
}

export function creditCardMask(v: string): string {
  if (!v) return ''

  // Remove caracteres que não são números ou espaços
  v = v.replace(/[^\d\s]/g, '')

  // Remove os espaços em branco
  v = v.replace(/\s/g, '')

  // Limita a quantidade de caracteres para 16
  v = v.slice(0, 16)

  // Aplica a máscara
  v = v.replace(/(\d{4})(?=\d)/g, '$1 ')

  return v
}

export const BRL = (value: number) =>
  currency(value, {
    decimal: ',',
    separator: '.',
    symbol: '',
  });
