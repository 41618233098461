import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function TextWithLabel({
  label,
  value = "",
}: {
  label: string;
  value?: string;
}) {
  return (
    <Box
      sx={{
        height: "auto",
        flexGrow: 1,
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Typography  component="label">
        {label}
      </Typography>

      <Typography  component="span" sx={{ fontWeight: "bold" }}>
        {value}
      </Typography>
    </Box>
  );
}
