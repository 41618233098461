export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  FORGOT_NEW_PASSWORD: "/forgot-password",

  SERVICES: {
    VIEW: (id: string) => `/services/${id}`,
  },

  CONTRACTS: {
    CREATE: (id: string) => `/contracts/${id}`,
    PAYMENT: (id: string) => `/contracts/payment/${id}`,
  },

  CLIENTS: {
    LIST: "/clients",
    CREATE: "/clients/create",
    EDIT: (id: string) => `/clients/edit/${id}`,
    VIEW: (id: string) => `/clients/view/${id}`,
  },
};
