import { AxiosError } from "axios";
import { SelectProps } from "components/Select";
import { ENDPOINTS } from "constants/endpoints";
import apiInstance from "services/api/instance";
import { handleErrorMessage } from "../errors";

export const getAll = async (): Promise<Forms[]> => {
  try {
    const getAllResponse = await apiInstance.get<FormsApiResponse[]>(
      ENDPOINTS.FORMS.GET_ALL
    );

    return getAllResponse.data;
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const get = async (id: string): Promise<FormsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<FormsApiResponse>(
      ENDPOINTS.FORMS.GET(id)
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const getNames = async (): Promise<SelectProps["options"]> => {
  try {
    const getAllResponse = await getAll();

    return (getAllResponse || []).map((form) => ({
      label: form.applicant_name,
      value: form.id.toString(),
    }));
  } catch (_getAllError) {
    return Promise.reject([]);
  }
};

export const create = async (
  form: FormsApiRequest
): Promise<FormsApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<FormsApiResponse>(
      ENDPOINTS.FORMS.CREATE,
      form
    );

    return creationResponse.data;
  } catch (creationError) {
    const axiosError = creationError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const update = async ({
  id,
  form,
}: {
  form: FormsApiRequest;
  id: string;
}): Promise<FormsApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<FormsApiResponse>(
      ENDPOINTS.FORMS.UPDATE(id),
      form
    );

    return updateResponse.data;
  } catch (updateError) {
    const axiosError = updateError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(ENDPOINTS.FORMS.DELETE(id));

    return deleteResponse.data;
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};
