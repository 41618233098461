import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import AutoRenewIcon from "@mui/icons-material/Autorenew";

const rotate = keyframes`
  from { rotate: 0deg; }
  to { rotate: 360deg; }
`;

type LoadingProps = {
  height?: string;
  width?: string;
};

const Loading = styled(AutoRenewIcon)<LoadingProps>`
  height: ${({ height = "50px" }) => height} !important;
  width: ${({ width = "50px" }) => width} !important;
  animation: ${rotate} 2s linear infinite;
`;

export default Loading;
