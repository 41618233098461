import { AxiosError } from "axios";
import { ENDPOINTS } from "constants/endpoints";
import apiInstance from "services/api/instance";
import { handleErrorMessage } from "../errors";
import { CLIENT_SESSION_STORAGE_KEY } from "constants/keys";
import { ApiService } from "..";
import { ROUTES } from "constants/routes";

export const getAll = async (): Promise<ClientsApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<ClientsApiResponse[]>(
      ENDPOINTS.CLIENTS.GET_ALL
    );

    return getAllResponse.data;
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const get = async (id: string): Promise<ClientsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<ClientsApiResponse>(
      ENDPOINTS.CLIENTS.GET(id)
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const refreshToken = async (): Promise<string> => {
  try {
    const getResponse = await apiInstance.get<string>(ENDPOINTS.CLIENTS.REFRESH_TOKEN)
    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const login = async (
  cpf: string,
  password: string,
): Promise<LoginApiResponse> => {
  try {
    const getResponse = await apiInstance.post<LoginApiResponse>(
      ENDPOINTS.LOGIN,
      { cpf, password }
    );

    localStorage.setItem(
      CLIENT_SESSION_STORAGE_KEY,
      JSON.stringify({ ...getResponse.data.client, token: getResponse.data.token })
    );
    ApiService.persistToken(getResponse.data.token)

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const checkIfCpfExist = async (cpf: string): Promise<boolean> => {
  try {
    const getResponse = await apiInstance.get<boolean>(
      ENDPOINTS.CLIENTS.GET_BY_CPF(cpf)
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const sendMailForgotPassword = async (cpf: string): Promise<ClientsForgotPasswordResponse> => {
  try {
    const getResponse = await apiInstance.post<ClientsForgotPasswordResponse>(
      ENDPOINTS.CLIENTS.SEND_MAIL_FORGOT_PASSWORD, { cpf, link: `${process.env.REACT_APP_MY_URL}${ROUTES.FORGOT_NEW_PASSWORD}`}
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const updatePasswordForgotPassword = async (data: UpdatePasswordForgotPasswordRequest): Promise<boolean> => {
  try {
    const getResponse = await apiInstance.post<boolean>(
      ENDPOINTS.CLIENTS.UPDATE_PASSWORD_FORGOT_PASSWORD, data
    );

    return getResponse.data;
  } catch (getError) {
    const axiosError = getError as AxiosError;
    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const create = async (
  client: ClientsApiRequest,
  token: string
): Promise<ClientsApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<ClientsApiResponse>(
      ENDPOINTS.CLIENTS.CREATE,
      {...client, country: 'Brasil'},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    localStorage.setItem(
      CLIENT_SESSION_STORAGE_KEY,
      JSON.stringify({...creationResponse.data, token: creationResponse.data.token})
    );
    ApiService.persistToken(creationResponse.data.token)
    return creationResponse.data;
  } catch (creationError) {
    const axiosError = creationError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const update = async ({
  id,
  client,
}: {
  client: ClientsApiRequest;
  id: string;
}): Promise<ClientsApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<ClientsApiResponse>(
      ENDPOINTS.CLIENTS.UPDATE(id),
      client
    );

    return updateResponse.data;
  } catch (updateError) {
    const axiosError = updateError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.CLIENTS.DELETE(id)
    );

    return deleteResponse.data;
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError;

    return Promise.reject(handleErrorMessage({ axiosError }));
  }
};
