import type { SelectProps } from "components/Select";

export const ACRONYMS_BRAZILIAN_STATES = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const SELECT_BRAZILIAN_STATES: SelectProps["options"] = [
  {
    label: "Acre",
    value: "AC",
  },
  {
    label: "Alagoas",
    value: "AL",
  },
  {
    label: "Amapá",
    value: "AP",
  },
  {
    label: "Amazonas",
    value: "AM",
  },
  {
    label: "Bahia",
    value: "BA",
  },
  {
    label: "Ceará",
    value: "CE",
  },
  {
    label: "Distrito Federal",
    value: "DF",
  },
  {
    label: "Espírito Santo",
    value: "ES",
  },
  {
    label: "Goiás",
    value: "GO",
  },
  {
    label: "Maranhão",
    value: "MA",
  },
  {
    label: "Mato Grosso",
    value: "MT",
  },
  {
    label: "Mato Grosso do Sul",
    value: "MS",
  },
  {
    label: "Minas Gerais",
    value: "MG",
  },
  {
    label: "Pará",
    value: "PA",
  },
  {
    label: "Paraíba",
    value: "PB",
  },
  {
    label: "Paraná",
    value: "PN",
  },
  {
    label: "Pernambuco",
    value: "PE",
  },
  {
    label: "Piauí",
    value: "PI",
  },
  {
    label: "Rio de Janeiro",
    value: "RJ",
  },
  {
    label: "Rio Grande do Norte",
    value: "RN",
  },
  {
    label: "Rio Grande do Sul",
    value: "RS",
  },
  {
    label: "Rondônia",
    value: "RO",
  },
  {
    label: "Roraima",
    value: "RR",
  },
  {
    label: "Santa Catarina",
    value: "SC",
  },
  {
    label: "São Paulo",
    value: "SP",
  },
  {
    label: "Sergipe",
    value: "SE",
  },
  {
    label: "Tocantins",
    value: "TO",
  },
];
