import { Button } from "components/Button";
import {  Container, ContainerImage, ContainerPrice } from "./styles";
import Image from "components/Image";
interface CardServiceProps {
  title: string;
  cover_image_url?: string;
  description: string;
  price: string;
  interest_free_installments?: number;
  installments_value?: string;
  onClick?: () => void;
}

export default function CardService({
  title,
  description,
  price,
  cover_image_url,
  interest_free_installments,
  installments_value,
  onClick,
}: CardServiceProps) {
  return (
    <Container  >
      <ContainerImage>
       <Image url={cover_image_url || ''} alt="imagem de capa" />
      </ContainerImage>
      <div className="container-title">
      <h3>{title}</h3>
      </div>
      <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
      </div>
      <div className="line" />
      <p>{description}</p>
      <ContainerPrice>
        <p className="price">{price}</p>
        {!!interest_free_installments && (
          <span><strong>{interest_free_installments}x</strong> de <strong>{installments_value}</strong> sem juros</span>
        )}
      </ContainerPrice>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button onClick={onClick} fullWidth style={{ maxWidth: '20rem' }}>Ver mais</Button>
      </div>
    </Container>
  );
}
