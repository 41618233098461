import { useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Header from "components/Header";
import FormRegister from "./form_register";
import FormLogin from "./form_login";
import { Option } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

type Sections = "login" | "register"

enum SectionsType {
  LOGIN = "login",
  REGISTER = "register"
}

export default function Add() {
  const navigate = useNavigate();
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const section = searchParams.get('section')
  const selectedSectionsType: Sections = useMemo(() => {
    return (section || 'register') as Sections
  },[section])

  const Sections = {
    [SectionsType.LOGIN]: <FormLogin />,
    [SectionsType.REGISTER]: <FormRegister />,
  };

  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{ backgroundColor: "var(--gray-1)", flexGrow: 1, py: 8 }}
      >
        <Container
          maxWidth={false}
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Option
                isActive={selectedSectionsType === SectionsType.LOGIN}
                onClick={() => {
                  navigate(`${ROUTES.LOGIN}?section=${SectionsType.LOGIN}`)
                }}
              >
                Login
              </Option>
              <Option
                isActive={selectedSectionsType === SectionsType.REGISTER}
                onClick={() => {
                  navigate(`${ROUTES.LOGIN}?section=${SectionsType.REGISTER}`)
                }}
              >
                Cadastre-se
              </Option>
            </Box>

            {Sections[selectedSectionsType]}
          </Box>
        </Container>
      </Box>
    </>
  );
}
