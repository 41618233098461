import { Container } from "./styles";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MUIMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useCallback, useState } from "react";
import { useGlobalState } from "contexts/global-state";
export default function Header() {
  const {
    logout,
    client
  } = useGlobalState()
  const navigate = useNavigate();
  const redirectRoot = () => {
    navigate(ROUTES.ROOT);
  };
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorElement(null)
  }, [])
  return (
    <Container>
      <img src={logo} alt="Logo" onClick={redirectRoot} />

      {client && (
        <div>
        <div className="container-user-menu">
          <p className="container-username">{client?.name}</p>
        <IconButton
          size="large"
          aria-label="Conta do usuário"
          aria-controls="menu-app_bar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          
        >
          <AccountCircle fontSize="large" />
        </IconButton>
        </div>
        <MUIMenu
          id="menu-app_bar"
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => { window.location.href = `${process.env.REACT_APP_WEB_URL_CLIENT}` }}>Minha área</MenuItem>
          <MenuItem onClick={logout}>Sair</MenuItem>
        </MUIMenu>
      </div>
      )}
    </Container>
  );
}
