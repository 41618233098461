import { DatePicker as MuiDatePicker  } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import br from "dayjs/locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { INPUT_MASK } from "constants/masks";
import { ptBR } from '@mui/x-date-pickers/locales';

interface DatePickerProps {
  label: string
  format?: string
  fullWidth?: boolean
  disableFuture?: boolean
  value?: string | Date | null
  error?: string
  onChange: (value?: string | Date | null) => void
}


export function DatePicker ({ 
  label,
  value,
  onChange,
  fullWidth,
  disableFuture,
  error,
  format = INPUT_MASK.DATE_LOCALE,
 }: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={br} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} >
      <MuiDatePicker
        label={label}
        disableFuture={disableFuture}
        value={value}
        format={format}
        views={["day", "month", "year"]}
        openTo="day"
        sx={{ width: fullWidth ? "100%": 'none' }}
        onChange={onChange}
        slotProps={{
          textField: {
            helperText: error,
            error: !!error
          },
        }}
      />
    </LocalizationProvider>
  )
}