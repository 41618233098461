import { devicesMinWidth } from "constants/media-queries";
import styled from "styled-components";

export const Container = styled.footer`
  width: calc(100% - 1rem);
  height: 80px;
  padding: 0.5rem;
  background: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 0.5rem 0.5rem;


  img {
    height: 90%;
    cursor: pointer;
  }

  .container-user-menu {
    display: flex;
    align-items: center
  }
  .container-username {
    display: none;
    @media ${devicesMinWidth.tablet} {
    display: flex;
  }
  }

  .container-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
  }

  .container-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
    h3 {
      font-size: 1rem;
      font-weight: 700;
    }
  }
`;
