import { devicesMinWidth } from "constants/media-queries";
import styled from "styled-components";

export const Container = styled.header`
  width: calc(100% - 1rem);
  height: 80px;
  padding: 0.5rem;
  background: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 0.5rem 0.5rem;

  img {
    height: 90%;
    cursor: pointer;
  }

  .container-user-menu {
    display: flex;
    align-items: center
  }
  .container-username {
    display: none;
    @media ${devicesMinWidth.tablet} {
    display: flex;
  }
  }
`;
