import { forwardRef, useRef, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MUISelect, { SelectProps as MUISelectProps } from '@mui/material/Select'

export type SelectProps = MUISelectProps & {
  label: string
  id: string
  autoFill?: boolean
  blank?: boolean
  helperText?: string
  formControlMargin?: 'normal' | 'dense' | 'none' | undefined
  options: Array<{
    label: string
    value: string | number
  }>
}

const Select = forwardRef(function Select(
  {
    id,
    label,
    options,
    value,
    onChange,
    autoFill = false,
    error,
    blank = true,
    helperText,
    formControlMargin = 'normal',
    ...selectProps
  }: SelectProps,
  ref,
) {
  const [shouldRemove, setShouldRemove] = useState<boolean>(autoFill)

  const inputReference = useRef<HTMLInputElement>(null)

  return (
    <FormControl fullWidth margin={formControlMargin} error={error}>
      <InputLabel id={`${id}-label`} shrink={!!value}>
        {label}
      </InputLabel>

      <MUISelect
        {...selectProps}
        value={value}
        ref={ref}
        labelId={`${id}-label`}
        id={id}
        label={label}
        inputProps={{
          inputRef: inputReference,
        }}
        notched={!!value}
        onChange={(event, child) => {
          const inputParent = (
            inputReference.current as unknown as { node: HTMLElement }
          ).node.parentElement

          const selectedValueElement = inputParent?.querySelector(
            `#${id}-selected-value`,
          )

          if (selectedValueElement && inputParent && shouldRemove) {
            inputParent.removeChild(selectedValueElement)
            setShouldRemove(false)
          }
          onChange?.(event, child)
        }}
      >
        {blank && (
          <MenuItem key="select" value="" id={`${id}-select`}>
            Selecione
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            id={`${id}-${option.value}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
})

export default Select
