import { devicesMinWidth } from "constants/media-queries";
import styled from "styled-components";

interface Props {
  isActive: boolean;
}

export const Option = styled.p<Props>`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0% 0%;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? "var(--white)" : "var(--gray-1)"};
  transition: background-color 0.2s ease-in-out;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--gray-4);
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  @media ${devicesMinWidth.tablet} {
    .register-button {
      max-width: 8rem;
    }
  }
`;
