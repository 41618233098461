import Footer from "components/Footer";
import Providers from "providers";
import React, { useCallback, useEffect } from "react";
import Router from "router";
import { ApiService } from "services/api";
import { CacheService } from "services/cache";
import jwtDecode from "utils/jwt-decode";

const INTERVAL_REFRESH_TOKEN_IN_MILLISECONDS =
  (Number(process.env.INTERVAL_IN_MINUTES_REFRESH_TOKEN) || 3) * 60000

function App() {
  const handleUpdateUser = useCallback(
    async (cachedUser: LoginApiResponse) => {
      const responseToken = await ApiService.Clients.refreshToken()
      CacheService.client.saveExpirationTokenDate(
        ((jwtDecode(responseToken)?.exp || 0)),
      )
      CacheService.client.saveCurrentUser({
        ...cachedUser,
        token: responseToken,
      })
      ApiService.persistToken(responseToken)
    },
    [],
  )
  
  const handleLoadPublicToken = useCallback(async () => {
    const cachedPublicToken = CacheService.client.getCurrentPublicToken()
    if(cachedPublicToken) {
      const expirationDate = await CacheService.client
      .getExpirationPublicTokenDate()
      const currentDate = new Date()
      if (currentDate < expirationDate) {
        return
      }
    }
    const publicToken = await ApiService.PublicKey.get();
    CacheService.client.saveCurrentPublicToken(publicToken);
    const decodedJwt = jwtDecode(publicToken)
    if(decodedJwt) {
      CacheService.client.saveExpirationPublicTokenDate(decodedJwt.exp);
    }
  },[])

  useEffect(() => {
    let timer: NodeJS.Timer | undefined
    if (!timer) {
      timer = setInterval(async () => {
        const cachedClient = CacheService.client.getCurrentClient()
        if (!cachedClient) return
        await handleUpdateUser(JSON.parse(cachedClient))
      }, INTERVAL_REFRESH_TOKEN_IN_MILLISECONDS)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [handleUpdateUser])


  useEffect(() => {
    handleLoadPublicToken()
  }, [handleLoadPublicToken])


  return (
    <Providers>
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>

      <Router />
      <div style={{ height: '1rem', background: "var(--gray-1)" }}></div>
      <Footer/>
      </div>
    </Providers>
  );
}

export default App;
