import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

type ButtonProps = {
  uppercase: boolean
  fullWidth: boolean
  variant?: 'contained' | 'outlined'
}

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) => (props.variant === 'contained' ? '#ff6606' : 'transparent')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'initial')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  padding: 15px 20px;
  font-weight: 600;
  color: ${(props) => (props.variant === 'contained' ? 'white' : '#ff6606')};
  text-align: center;
  border-radius: 5px;
  border: ${(props) => (props.variant === 'contained' ? 'none' : '1px solid #ff6606')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 20px;
  }



  :disabled {
    background: #dedfe4;
  }
`
