import type { AxiosError } from "axios";

type ErrorMessage = Record<
  number | string,
  {
    status: number;
    message: string;
  }
>;

const errors: ErrorMessage = {
  401: {
    status: 401,
    message: "Usuário não autorizado",
  },
  404: {
    status: 404,
    message: "Erro na requisição",
  },
  'this client already register': {
    status: 404,
    message: "Cliente já cadastrado",
  },
  'incorrect cpf/password combination.': {
    status: 404,
    message: "CPF ou senha incorretos",
  },
};

export const handleErrorMessage = ({
  axiosError,
  customErrors = {},
}: {
  axiosError: AxiosError;
  customErrors?: ErrorMessage;
}) => {
  return (
    { ...errors, ...customErrors }[(axiosError.response?.data as { message: string })?.message?.toLowerCase()] || { ...errors, ...customErrors }[axiosError.response?.status as number] || {
      status: axiosError.response?.status,
      message:
        (axiosError.response?.data as { message: string })?.message || "",
    }
  );
};
