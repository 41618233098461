import { useCallback, useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";

import FormContract from "./form_contract";
import Header from "components/Header";
import { ApiService } from "services/api";
import { CLIENT_SESSION_STORAGE_KEY, SELECTED_SERVICE } from "constants/keys";

interface FormData {
  name: string;
  cpf: string;
  email: string;
}

function completeArrayParticipants({
quantity,
oldArray
}: {
  quantity?: number
  oldArray: FormData[]
}) {
  const objectsInitialRemainingParticipants = Array.from({length: (quantity||0) - oldArray.length}, () => ({
    name: '',
      cpf: '',
      email: '',
  }));
  return oldArray.concat(objectsInitialRemainingParticipants);
}

export default function Add() {
  const navigate = useNavigate();
  const [client] = useState(
    localStorage.getItem(CLIENT_SESSION_STORAGE_KEY) &&
      JSON.parse(localStorage.getItem(CLIENT_SESSION_STORAGE_KEY) as string)
  );

  const { id } = useParams<{ id: string }>();
  const [service, setService] = useState<Services>(
    localStorage.getItem(SELECTED_SERVICE) &&
      JSON.parse(localStorage.getItem(SELECTED_SERVICE) as string)
  );

  if (!id) navigate(ROUTES.ROOT);

  const validateToken = useCallback(async () => {
    if (!client) navigate(ROUTES.LOGIN);
  }, [navigate, client]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const [fields, setFields] = useState<FormData[]>([
    {
      name: client?.name,
      cpf: client?.cpf,
      email: client?.email,
    },
  ]);

  const getService = useCallback(async () => {
    if (!id) return;

    const data = await ApiService.Services.get(id);
    if(data?.quantity && data?.quantity > 1) {
      setFields(oldFields => (completeArrayParticipants({
        oldArray: oldFields,
        quantity: data.quantity
      })))
    }
    setService(data);
  }, [id]);

  useEffect(() => {
    getService();
  }, [getService]);

  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{ backgroundColor: "var(--gray-1)", flexGrow: 1, py: 8 }}
      >
        <Container
          maxWidth={false}
          sx={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                Contratar Serviço
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <FormContract
              initialFields={fields}
              service={service}
              client={client}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}
