import apiInstance from "services/api/instance";

import * as Contracts from "./contracts";
import * as Clients from "./clients";
import * as ClientOrigins from "./client-origins";
import * as Coupons from "./coupons";
import * as Services from "./services";
import * as ServicesCategories from "./services-categories";
import * as Forms from "./forms";
import * as PublicKey from "./publicKey";
import * as Pagseguro from './pagseguro'
import { PUBLIC_TOKEN_SESSION_STORAGE_KEY } from "constants/keys";

const persistToken = (token: string) => {
  apiInstance.defaults.headers["Authorization"] = `Bearer ${token}`;
};

const storagePublicToken = localStorage.getItem(PUBLIC_TOKEN_SESSION_STORAGE_KEY)
if (storagePublicToken) {
  persistToken(storagePublicToken)
}


export const ApiService = {
  persistToken,
  Contracts,
  Clients,
  ClientOrigins,
  Coupons,
  Services,
  ServicesCategories,
  Forms,
  PublicKey,
  Pagseguro,
};
