import { ROUTES } from "constants/routes";
import * as Pages from "pages";
import { RouteObject } from "react-router-dom";

const LoginRoutes: RouteObject[] = [
  {
    path: ROUTES.LOGIN,
    element: <Pages.Login />,
  },
];

export default LoginRoutes;
