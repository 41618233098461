import React from 'react'
import { Modal, ModalProps } from 'components/Modal'
import Loading from 'components/LoadingFullPage'

export function ModalLoading({ children, ...props }: ModalProps) {
  return (
    <Modal {...props}>
      <div>
        <Loading />
        {children}
      </div>
    </Modal>
  )
}
