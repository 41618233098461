import React from 'react'
import { Modal, ModalProps } from 'components/Modal'
import {
  Button,
} from "@mui/material";
type ModalConfirmProps = ModalProps & {
  onClose: () => void
  onAccept: () => void
}

export function ModalConfirm({ onClose, onAccept, children, ...props }: ModalConfirmProps) {
  return (
    <Modal
      footer={
        <>
          <Button onClick={onClose} variant="outlined">
            Não
          </Button>

          <Button onClick={onAccept}>Sim</Button>
        </>
      }
      {...props}
      onClose={onClose}
      
    >
      {children}
    </Modal>
  )
}
