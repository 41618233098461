import { createBrowserRouter, RouterProvider } from "react-router-dom";

import contractsRoutes from "./contracts";
import rootPageRoutes from "./services";
import loginPageRoutes from "./login";
import { ROUTES } from "constants/routes";
import ChangePassword from "pages/change-password";

const router = createBrowserRouter([
  ...contractsRoutes,
  ...rootPageRoutes,
  ...loginPageRoutes,
  {
    path: ROUTES.FORGOT_NEW_PASSWORD,
    element: <ChangePassword />,
  }
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
