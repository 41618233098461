import styled from "styled-components";

export const ContainerImage = styled.div<{ stretch?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 220px;
  min-height: 320px;
  img {
    max-width: ${(props) => props.stretch ? 'none' : '320px'};
    max-height:${(props) => props.stretch ? 'none' : '320px'};
  }
`;