import Box from "@mui/material/Box";
import Loading from "components/Loading";

export default function LoadingFullPage() {
  return (
    <Box
      component="main"
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loading />
    </Box>
  );
}
