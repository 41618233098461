import {

  Container,
  ContainerDescription,
  ContainerImageService,
  ContainerPrice,
  ContainerResume,
  ContainerServiceBuy,
  ContainerServiceInfo,
  Content,
  Divider,
  NoticeModalContainer,
} from "./styles";

import { MONEY_MASK } from "constants/masks";

import Header from "components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { ApiService } from "services/api";
import { Button } from "components/Button";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS, SELECTED_SERVICE } from "constants/keys";

import LoadingFullPage from "components/LoadingFullPage";
import { Grid } from "@mui/material";
import Image from "components/Image";
import { useGlobalState } from "contexts/global-state";
import { Modal } from "components/Modal";
import { useCallback, useLayoutEffect, useRef, useState } from "react";



export default function ServiceDetails() {
  const titleServiceRef = useRef<HTMLDivElement>(null)
  const { logged } = useGlobalState();
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleSelectService,  } = useGlobalState();
  const [openModalNotice, setOpenModalNotice] = useState(true)
  const handleRedirect = async () => {
    if(logged) {
      const service = localStorage.getItem(SELECTED_SERVICE);
      if (service) {
        navigate(`/contracts/${JSON.parse(service).id}`);
        return;
      }
    }
    navigate(ROUTES.LOGIN);
  };


  const { data: service } = useQuery<Services>(
    QUERY_KEYS.SERVICES.VIEW(id as string),
    () => ApiService.Services.get(id as string),
  )

  const getVideoId = useCallback((link: string) => {
    if (!link) return ''

    const currentUrl = new URL(link)
    const searchParams = currentUrl.searchParams
    const videoId = searchParams.get('v') || ''

    return videoId
  }, [])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useLayoutEffect(() => {
    handleSelectService(service)
  }, [handleSelectService, service])

  if(!service) {
    return <LoadingFullPage />
  }


  return (
    <>
        {service?.youtube_url && (
          <Modal visible={openModalNotice} onClose={() => setOpenModalNotice(false)} style={{ width: '36.5rem' }}>
          <NoticeModalContainer>

  
            <iframe
              width="auto"
              height="auto"
              src={`https://www.youtube.com/embed/${getVideoId(service.youtube_url)}`}
              title="YouTube video player"
              style={{ borderWidth: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
  
            <div style={{ display: 'flex', justifyContent: 'center', padding: '0.5rem' }}>
              <Button onClick={() => setOpenModalNotice(false)}>Fechar</Button>
            </div>
          </NoticeModalContainer>
        </Modal>

    )}
    <Container ref={titleServiceRef}>
      <Header />
      <Content>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
        <ContainerImageService>
          <Image url={service?.cover_image_url || ''} alt="imagem de capa" />
        </ContainerImageService>
        </Grid>

       <Grid item xs={12}  md={6}>
       <ContainerServiceInfo>
          <h3>{service?.name}</h3>
          <ContainerPrice>
            <p className="price">
             {MONEY_MASK(service?.value || 0)}
            </p>
          </ContainerPrice>
          {!!service?.interest_free_installments && !!service?.value && (
            <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <CreditCardIcon sx={{ color: 'var(--primary-color)', mr: '5px' }}/>
            <p className="container-interest-free-installments"><strong>{service?.interest_free_installments}x</strong> de <strong>{MONEY_MASK(service?.value / service?.interest_free_installments)}</strong> sem juros</p>
            </div>
            </>
          )}

          <ContainerServiceBuy>
            <Button fullWidth onClick={handleRedirect} style={{ maxWidth: '20rem' }}>Contratar</Button>
          </ContainerServiceBuy>

          <ContainerResume>
          {service?.resume}
          </ContainerResume>
        </ContainerServiceInfo>
       </Grid>
        </Grid>

        <Divider />

        <ContainerDescription>
        {service?.description && <p>{service.description}</p>}
          {service?.image_description_url && (
            <Image
              url={service.image_description_url}
              alt="descrição do serviço"
              stretch
            />
          )}
         
        </ContainerDescription>

        
      </Content>
    </Container>

    </>
  );
}
