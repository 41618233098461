import * as yup from "yup";

const contractSchema = yup.object().shape({
  coupon_id: yup.string(),
  discount: yup.string(),
  coupon_discount_value: yup.string(),
  note: yup.string(),
  status: yup.string(),

});

const formsSchema = {
  applicant_name: yup.string().required("Campo obrigatório"),
  cpf: yup.string().required("Campo obrigatório"),
  email: yup.string().required("Campo obrigatório"),
};

export { contractSchema, formsSchema };
