import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useGlobalState } from "contexts/global-state";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ApiService } from "services/api";
import { loginSchema, forgotPasswordSchema } from "./form-validation";
import InputMask from "components/InputMask";
import InputPassword from "components/InputPassword";
import { Form } from "./styles";
import { useNavigate } from "react-router-dom";
import { isInvalidCpf } from "constants/masks";
import { Button } from "components/Button";
import { SELECTED_SERVICE } from "constants/keys";
import { Modal } from "components/Modal";
import LoadingFullPageWithOverlay from "components/LoadingFullPageWithOverlay";
import { cpfMask } from "utils/mask";

export default function FormClient() {
  const navigate = useNavigate();
  const { openSuccessToast, openErrorToast } = useGlobalState();
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalForgotPassword, setOpenModalForgotPassword] = useState(false)
  const reactHookFormForgotPassword = useForm<ForgotPasswordRequest>({
    resolver: yupResolver(forgotPasswordSchema),
  })

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    watch,
  } = useForm<LoginApiRequest>({
    resolver: yupResolver(loginSchema),
  });

  const inputProps = useCallback(
    (fieldName: keyof LoginApiRequest) => {
      return { InputLabelProps: { shrink: !!watch(fieldName) } };
    },
    [watch]
  );

  const addNewClient = useMutation({
    mutationFn: async ({ cpf, password }: LoginApiRequest) => {
      await ApiService.Clients.login(cpf, password);
    },
    onSuccess: (_data, client: LoginApiRequest) => {
      openSuccessToast({
        message: `Login efetuado com sucesso!`,
      });

      const service = localStorage.getItem(SELECTED_SERVICE);

      if (service) {
        navigate(`/contracts/${JSON.parse(service).id}`);
        return;
      }

      navigate("/");
    },
    onError: (error: ApiError, client: LoginApiRequest) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar este cliente: ${error.message}`,
      });
    },
  });

  const handleSubmitForgotPassword = useCallback(async ({ cpf }: ForgotPasswordRequest) => {
    setOpenModalForgotPassword(false)
    setIsLoading(true)
    try {
      const responseForgotPassword = await ApiService.Clients.sendMailForgotPassword(cpf)
      openSuccessToast({ message: `Foi enviado um email para ${responseForgotPassword.send_email} com as instruções` })
    } catch (error: any) {
      openErrorToast({
        message: (error as ApiError).message || 'Erro ao adicionar este cliente'
      })
    } finally {
      setIsLoading(false)
      reactHookFormForgotPassword.reset()
    }
    
  }, [openErrorToast, openSuccessToast, reactHookFormForgotPassword])


  return (
    <>
    {isLoading && <LoadingFullPageWithOverlay />}
    <Form
      onSubmit={handleSubmit((client: LoginApiRequest) => {
        try {
          addNewClient.mutate(client);
        } catch (error) {}
      })}
    >
      <Card sx={{ maxWidth: "500px" }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Controller
                name="cpf"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      error={!!errors.cpf?.message || passwordError}
                      helperText={
                        errors.cpf?.message || passwordError
                          ? "CPF inválido"
                          : ""
                      }
                      fullWidth
                      label="CPF"
                      id="cpf"
                      onChange={(e) => {
                        e.target.value = cpfMask(e.target.value)
                        onChange(e)
                      }}
                      value={value}
                      {...inputProps("cpf")}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputPassword
                fullWidth
                label="Senha"
                id="password"
                type="password"
                {...register("password")}
                inputProps={{
                  pattern: "^.{6,}$",
                  ...inputProps("password"),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <span
                style={{ color: 'rgba(255, 102, 6, 0.7)', cursor: 'pointer' }}
                onClick={() => setOpenModalForgotPassword(true)}
              >
                Esqueci minha senha
              </span>
            </Grid>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{
                marginLeft: "auto",
                display: "block",
                marginTop: "1em",
              }}
            >
              Login
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Form>
    <Modal
      visible={openModalForgotPassword}
      onClose={() => {
        setOpenModalForgotPassword(false)
      }}
    >
     <Form onSubmit={reactHookFormForgotPassword.handleSubmit(data => {
      handleSubmitForgotPassword(data)
     })}>
     <Grid container spacing={3} xs={12}>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Controller
                name="cpf"
                control={reactHookFormForgotPassword.control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      error={!!errors.cpf?.message || passwordError}
                      helperText={
                        errors.cpf?.message || passwordError
                          ? "CPF inválido"
                          : ""
                      }
                      fullWidth
                      label="CPF"
                      id="cpf"
                      onChange={(e) => {
                        e.target.value = cpfMask(e.target.value)
                        onChange(e)
                      }}
                      value={value}
                      {...inputProps("cpf")}
                    />
                  );
                }}
              />
            </Grid>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{
                marginLeft: "auto",
                display: "block",
                marginTop: "1em",
              }}
            >
              Enviar
            </Button>
          </Grid>
     </Form>
    </Modal>
    </>
  );
}
