import { ROUTES } from "constants/routes";
import * as Pages from "pages";
import type { RouteObject } from "react-router-dom";

const ContractsRoutes: RouteObject[] = [
  {
    path: ROUTES.CONTRACTS.CREATE(":id"),
    element: <Pages.AddContracts />,
  },
  {
    path: ROUTES.CONTRACTS.PAYMENT(":id"),
    element: <Pages.PaymentContracts />,
  },
];

export default ContractsRoutes;
